<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :show.sync="CrearMRol"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="ComputedItem"
            :fields="fieldsRol"
            :loading="LoadingRol"
            column-filter            
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
          <template #RoleId="{item}">
            <td>
              <CInputCheckbox
                custom
                class="checkbook-filter ml-1"
                :checked="item.checked"
                @update:checked="actRol(item.RoleId,$event)"
              />
            </td>
          </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton color="wipe" @click="CrearMRol=false">
            <CIcon name="x"/>&nbsp;
            {{$t('label.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';

function fieldsRol(){
  return[
          { 
            key: 'RoleId', 
            label:'',
            sorter: false,
            filter: false,
            _style:'width:1%; min-width:5px'
          },
          { key: 'RoleNameEs', label:this.$t('label.name')+''+('(ES)'),_classes:'text-center'},
          { key: 'RoleNameEs', label:this.$t('label.name')+''+('(EN)'),_classes:'text-center'},
        ];
}        

//DATA
function data () {
  return {
    //MODELO
    ModuleId: '',
    UserId: '',
    // fieldsRol: fieldsRol,
    itemsRol: [],
    selectedRol: [],
    allRol: [],
    CrearMRol: false,
    LoadingRol: false,
    Loading: false,
    tituloModal: '',
    Status: ''
  }
}

//METHOD
function listarRolesByModulo(modulo) {
  let listado = [];
  this.selectedRol = [];
  this.selectedRol.length = 0;
  this.allRol = [];
  this.allRol.length = 0;

  this.$http.get("Role-by-module", { ModuleId: modulo })
  .then((response) => {
    listado = [...response.data.data];
    listado.forEach((ob)=> {
      this.Status = (ob.Status=="ACTIVO") ? 1 : 0;
      this.allRol.push({
        RoleId: ob.RoleId,
        RoleNameEs: ob.RoleNameEs,
        RoleNameEn: ob.RoleNameEn,
        ModuleIdRol: ob.ModuleRoleId,
        Status: this.Status
      })
      if (this.Status==1) this.selectedRol.push(ob.RoleId)
    });
    
    this.Loading = false;
  })
  .catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading = false;
  });
}

function actRol(id,event) {
  this.Loading = true;
  this.Status = event ? 1 : 0;
  let bandera = this.allRol.some(item => item.RoleId==id);
  let ModuleRoleJson = [];
  let res = [];
  
  if (bandera) {
    let ModuloRol = this.allRol.find(item => item.RoleId==id);
    ModuleRoleJson = {
      TpModuleNavegationId: this.NavigationFilter,
      ModuleRoleId: ModuloRol.ModuleIdRol,
      ModuleId: this.ModuleId,
      RoleId: ModuloRol.RoleId,
      Status: this.Status,
      UserId: this.user.UserId,
    };

    this.$http.put("ModuleRole-update", ModuleRoleJson, { root: 'ModuleRoleJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
    });
  } else {
    ModuleRoleJson = {
      TpModuleNavegationId: this.NavigationFilter,
      ModuleId: this.ModuleId,
      RoleId: id,
      UserId: this.user.UserId,
    };
    this.$http.post("ModuleRole-insert", ModuleRoleJson, { root: 'ModuleRoleJson' })
    .then(response => {
      res = [...response.data.data];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      let _lang = this.$i18n.locale;
      this.allRol.push({
        TpModuleNavegationId: this.NavigationFilter,
        RoleId: id,
        RoleName: _lang=='en'?res[0].ModuleNameEn:res[0].ModuleNameEs,
        ModuleIdRol: res[0].ModuleRoleId,
        Status: 1
      })
      
      this.Loading= false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
    });
  }
}

function ComputedItem() {
  if (this.ValorMRol?.Roles&&this.ValorMRol?.Roles.length!=0) {
    return this.ValorMRol.Roles.map(item => {
      let checkeds = this.allRol.some((e) => e.RoleId == item.value);
      return {
        ...item,
        RoleId: item.value,
        RoleNameEs: item.label,
        RoleNameEn: item.label,
        checked: checkeds,
      }
    })
  } else {
    return [];
  }
}

export default {
  name: 'roles-by-module',
  data,
  mixins: [GeneralMixin],
  props: {
    ValorMRol: null, NavigationFilter: String,
  },
  watch: {
    ValorMRol: function () {
      if (this.ValorMRol) {
        this.LoadingRol = true;
        this.Loading = true;
        let _lang = this.$i18n.locale;
        let name =_lang =='en' ? this.ValorMRol.NombreModuloEn : this.ValorMRol.NombreModuloEs;
        this.tituloModal=this.$t('label.module') + ' ' +this.$t('label.role')+' '+name;
        this.CrearMRol = true;
        this.ModuleId = this.ValorMRol.ModuleId;
        this.LoadingRol = false;
        this.UserId = this.user.UserId;
        this.listarRolesByModulo(this.ModuleId);
      }
    }
  },
  methods: {
    listarRolesByModulo,
    actRol,
  },
  computed: {
    ComputedItem,
      fieldsRol,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>